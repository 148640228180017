<template>
  <div class="connect-contaner">
    <el-row class="device-list">
      <el-row type="flex" align="middle" justify="space-between">
        <p class="title">联系我们</p>
      </el-row>
    </el-row>
    <div class="show-box">
      <p class="com-name">{{ showConText.companyName }}</p>
      <el-row type="flex" align="center" class="msg">
        <img src="@/assets/images/home/jichuang.png" alt="" />
        <div class="box">
          <p>官网：{{ showConText.officialWeb }}</p>
          <p>公司邮箱：{{ showConText.officialMail }}</p>
          <p>公司地址：{{ showConText.companyAdress }}</p>
        </div>
      </el-row>
    </div>
    <div class="contact-box">
      <div class="item">
        <img src="@/assets/images/kefu12.png" alt="" />
        <p>呼叫客服</p>
        <p>9:00-18:00</p>
        <template v-if="showConText.officialWeChat">
          <p
            v-for="(item, index) in showConText.officialTel.split(',')"
            :key="index"
          >
            {{ item }}
          </p>
        </template>
      </div>
      <div class="item">
        <img src="@/assets/images/home/weixin.png" alt="" />
        <p>客服微信</p>
        <template v-if="showConText.officialWeChat">
          <p
            v-for="(item, index) in showConText.officialWeChat.split(',')"
            class="abtn"
            :key="index"
            :data-clipboard-text="item"
            @click="copy"
          >
            {{ item }} <span>复制</span>
          </p>
        </template>
        <!-- <p>8963253301 <span>复制</span></p> -->
      </div>
      <div class="item">
        <img src="@/assets/images/home/qq.png" alt="" />
        <p>客服QQ</p>
        <template v-if="showConText.officialWeChat">
          <p
            v-for="(item, index) in showConText.officialWeChat.split(',')"
            :key="index"
            class="abtn"
            :data-clipboard-text="item"
            @click="copy"
          >
            {{ item }} <span>复制</span>
          </p>
        </template>
        <!-- <p>8963253301 <span>复制</span></p> -->
      </div>
    </div>
  </div>
</template>

<script>
import ClipboardJS from "clipboard";
export default {
  data() {
    return {
      userCompMsg: {},

      showConText: {},
    };
  },

  mounted() {},
  created() {
    //查询登录用户所属公司
    // this.getUserComp();

    //根据分组查询系统配置
    this.getConfig();
  },

  methods: {
    // 分享弹窗复制
    copy() {
      let that = this;
      var clipboard = new ClipboardJS(".abtn");

      clipboard.on("success", function (e) {
        that.$message({
          message: "复制成功",
          type: "success",
          duration: 500,
        });
        e.clearSelection();
      });

      clipboard.on("error", function (e) {
        that.$message({
          message: "复制失败",
          type: "error",
        });
      });

      setTimeout(() => {
        this.shareVisible = false;
      }, 500);
    },

    //根据分组查询系统配置
    getConfig() {
      let paramData = {
        configGroup: "tellUs",
      };
      this.$API
        .getConfig(paramData)
        .then((res) => {
          if (res.code == 1) {
            this.showConText = res.data;
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //查询登录用户所属公司
    getUserComp() {
      this.$API
        .getUserComp()
        .then((res) => {
          if (res.code == 1) {
            this.userCompMsg = res.data[0];
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.connect-contaner {
  box-sizing: border-box;
  padding: 32px;
  .device-list {
    width: 100%;
    margin-top: 0px;
    border-bottom: 1px solid #eeeeee;
    .title {
      padding-bottom: 16px;
      font-size: 22px;
      font-weight: bold;
      color: #333333;
      border: none;
    }
  }
  .show-box {
    .com-name {
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      text-align: center;
      margin-top: 32px;
    }
    .msg {
      margin-top: 32px;
      margin-left: 194px;
      img {
        width: 240px;
        height: 160px;
        margin-right: 24px;
      }
      .box {
        height: 160px;
        box-sizing: border-box;
        padding-top: 36px;
        p {
          font-size: 14px;
          color: #666666;
          margin-bottom: 16px;
        }
      }
    }
  }
  .contact-box {
    width: 624px;
    display: flex;
    justify-content: space-between;
    margin-top: 104px;
    margin-left: 176px;
    .item {
      display: flex;
      align-items: center;
      flex-direction: column;
      cursor: pointer;
      img {
        width: 40px;
        height: 40px;
        margin-bottom: 16px;
      }
      p {
        margin-bottom: 10px;
        font-size: 16px;
        color: #333333;
        span {
          color: #007bd9;
          margin-left: 12px;
        }
      }
    }
  }
}
</style>